<template>
  <div class="login-container">
    <iframe :src="('https://www.bimcc.com/web-page/home?clear')" frameborder="0" style="width:0px;height:0px"></iframe>
    <transition name="fade" mode="out-in">
      <div v-if="mode === 'loginForm' || mode === 'choseArchi'" class="transition-warp" key="bg-one">
        <div class="jump-btn" @click="jumpToSaas">
          体验抢先版
        </div>
        <div class="bg-warp">
          <div class="shape-1" :style="shape1"></div>
          <div class="shape-2" :style="shape1"></div>
          <div class="shape-3" :style="shape1"></div>
        </div>
        <div class="login-warp">
          <div class="login-warp-bg">
          </div>
          <!-- 登录组件， 登录全在内部实现 -->
          <LoginCom v-if="mode === 'loginForm'" @changeMode="handleChangeMode" />
          <!-- 选择架构 -->
          <ChoseArchi v-else @changeMode="handleChangeMode" :data="data" />
        </div>
        <img class="layer-city" src="../newLogin/images/layer-city.png">
      </div>
      <div v-else class="transition-warp" key="bg-tow">
        <div class="jump-btn" @click="jumpToSaas">
          体验抢先版
        </div>
        <div class="other-bg-icon" @click="$router.push({ path: 'web-page/home' })">
        </div>
        <div class="content-warp">
          <!-- 忘记密码 -->
          <ForgetPassword @changeMode="handleChangeMode" v-if="mode === 'forgetPasswordForm'" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import LoginCom from '@/manage-views/views/login/secondEdition/components/LoginCom.vue'
import ForgetPassword from '@/manage-views/views/login/newLogin/components/ForgetPassword.vue'
import ChoseArchi from '@/manage-views/views/login/newLogin/components/ChoseArchi.vue'
import { setToken, getToken } from '@/utils/tools'
import { userUseCompany } from '@/api/userInfo'
import { userInfo } from '@/api/user'
import { mapState } from 'vuex';
export default {
  components: {
    LoginCom,
    ForgetPassword,
    ChoseArchi
  },
  data() {
    return {
      mode: 'loginForm',
      data: null
    }
  },
  computed: {
    ...mapState(['loginVersion']),
    shape1(){
      if(this.loginVersion ==='V2.0'){
        return 'background: linear-gradient(180deg, #A699F0 0%, #44EAE1 100%), #D9D9D9;'
      }else{
        return 'background: linear-gradient(212deg, #2A98FA 3.32%, #36D1EC 39.83%, #71F0A2 104.73%);'
      }
    },
  },
  mounted() {
    this.reLogin()
  },
  methods: {
    // 重新登录
    reLogin() {
      if (getToken() || location.href.includes('?token=')) {
        let token = '';
        if (getToken()) {
          token = getToken()
        }
        if (location.href.includes('?token=')) {
          token = location.href.split('token=')[1];
        }
        userInfo(token).then((res) => {
          if (res.data.code === 200) {
            this.$message.success('登录成功！')
            setToken(token)
            localStorage.setItem('is_company', res?.data?.data?.is_company)
            this.$store.commit('setUserInfo',res.data.data)
            this.companyList = res.data.data.saas
            if (res.data.data.saas_admin == 1) {
              localStorage.setItem('company_id', 0) // 当前选择的系统的所有信息
              userUseCompany({ company_id: 0 }).then((r) => {
                localStorage.setItem(
                  'saas',
                  JSON.stringify(res.data.data.saas.length ? res.data.data.saas[0] : '')
                )
                localStorage.setItem('saasTargetArchi',JSON.stringify(r?.data?.data?.department_info))
                this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                this.$router.push({ path: '/home' })
                if (r.status && r.status !== 200) {
                  return false
                }
              })
              return false
            }
            if (res.data.data.saas && res.data.data.saas.length == 1) {
              localStorage.setItem(
                'saas',
                JSON.stringify(
                  res.data.data.saas.length ? res.data.data.saas[0] : ''
                )
              ) // 当前选择的系统的所有信息
              localStorage.setItem('company_id', res?.data.data?.saas[0].id)
              userUseCompany({ company_id: res?.data.data?.saas[0].id }).then(
                (r) => {
                  if (r.data && r.data.code !== 200) {
                    localStorage.setItem(
                      'saasTargetArchi',
                      JSON.stringify(r?.data?.data?.department_info)
                    )
                    this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                    return false
                  }
                  this.$router.push({ path: '/home' })
                }
              )
            } else {
              this.handleChangeMode('choseArchi', res.data.data)
            }
          }
        })
      }
      if (location.href.includes('?token=')) {
        let token = location.href.split('token=')[1]
        userInfo(token).then((res) => {
          if (res.data.code === 200) {
            this.$message.success('登录成功！')
            setToken(token)
            localStorage.setItem('system_style', 1)
            localStorage.setItem('is_company', res?.data?.data?.is_company)
            this.$store.commit('setUserInfo',res.data.data)
            this.companyList = res.data.data.saas
            if (res.data.data.saas_admin == 1) {
              localStorage.setItem('company_id', 0) // 当前选择的系统的所有信息
              userUseCompany({ company_id: 0 }).then((r) => {
                localStorage.setItem(
                  'saas',
                  JSON.stringify(
                    res.data.data.saas.length ? res.data.data.saas[0] : ''
                  )
                )
                localStorage.setItem(
                  'saasTargetArchi',
                  JSON.stringify(r?.data?.data?.department_info)
                )
                this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                this.$router.push({ path: '/home' })
                if (r.status && r.status !== 200) {
                  return false
                }
              })
              return false
            }
            if (res.data.data.saas && res.data.data.saas.length == 1) {
              localStorage.setItem(
                'saas',
                JSON.stringify(
                  res.data.data.saas.length ? res.data.data.saas[0] : ''
                )
              ) // 当前选择的系统的所有信息
              localStorage.setItem('company_id', res?.data.data?.saas[0].id)
              userUseCompany({ company_id: res?.data.data?.saas[0].id }).then(
                (r) => {
                  localStorage.setItem(
                    'saasTargetArchi',
                    JSON.stringify(r?.data?.data?.department_info)
                  )
                  this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                  if (r.data && r.data.code !== 200) {
                    return false
                  }
                  this.$router.push({ path: '/home' })
                }
              )
            } else {
              this.handleChangeMode('choseArchi', res.data.data)
            }
          }
        })
      }
      if (location.href.includes('index=1')) {
        this.mode = 'loginForm'
      }
    },
    handleChangeMode(type, data = null) {
      this.mode = type
      this.data = data
      if(this.$route?.query?.followed === 1) localStorage.setItem('officialAccount', '1')
    },
    jumpToSaas() {
      window.open(`https://saas.bimcc.com/login`);
    }
  }
}
</script>
<style lang="less" scoped>
  @keyframes shape1am {
    from {
      left: 77px;
      top: -380px;
    }
    to {
      left: 77px;
      top: -180px;
    }
  }
  @keyframes shape2am {
    from {
      right: -255px;
      top: 200px;
    }
    to {
      right: -105px;
      top: 200px;
    }
  }
  @keyframes shape3am {
    from {
      left: 46.03px;
      bottom: -500px;
    }
    to {
      left: 46.03px;
      bottom: -350px;
    }
  }
  @mainheight: 670px;
  @mainwidth: 1260px;  // 1500
  .login-container{
    width: 100%;
    height: 100%;
    background: #E8ECF5;
    .transition-warp{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .jump-btn {
        position: fixed;
        top: 4.8%;
        right: 2%;
        z-index: 1;
        width: auto;
        padding: 0 12px;
        height: 28px;
        line-height: 28px;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6;
        background: linear-gradient(45deg, rgb(54, 209, 236) 24.64%, rgb(54, 209, 236) 75.36%), linear-gradient(271deg, #5AABFB 0%, #85ADFF 25%, rgb(54, 209, 236) 50%, rgb(54, 209, 236) 100%);
        color: var(--text-on-brand-primary, #FFF);
        font-family: "MiSans VF";
        font-size: 15px;
        font-style: normal;
        font-weight: 380;
        line-height: 22px;
        opacity: 0.7;
        transition: transform 100ms linear;
        &:hover {
          transform: scale(1.02);
        }
      }
      .bg-warp{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;
        .shape-1{
          position: absolute;
          width: 636.38px;
          height: 636.38px;
          border-radius: 50%;
          left: 77px;
          top: -380px;
          background: linear-gradient(180deg, #44EAE1 0%, #A699F0 100%), #D9D9D9;
          transform: rotate(74.21deg);
          animation: shape1am 5s linear infinite alternate;
        }
        .shape-2{
          position: absolute;
          width: 511.09px;
          height: 785.25px;
          right: -255px;
          top: 200px;
          background: linear-gradient(180deg, #44EAE1 0%, #A699F0 100%);
          border-radius: 28px;
          transform: rotate(-41.59deg);
          animation: shape2am 6s linear infinite alternate;
        }
        .shape-3{
          position: absolute;
          width: 511.09px;
          height: 785.25px;
          left: 46.03px;
          bottom: -500px;
          background: linear-gradient(180deg, #44EAE1 0%, #A699F0 100%);
          border-radius: 28px;
          transform: rotate(-111.47deg);
          animation: shape3am 7s linear infinite alternate;
        }

      }
      .login-warp{
        margin: auto;
        position: relative;
        @media (min-width: 1720px) {
          width: 78.12%;
          height: 72.30vh;
        }
        @media (max-width: 1720px) {
          width: @mainwidth;
          height: @mainheight;
        }
        box-sizing: border-box;
        border-radius: 32px;
        border: 2px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 0.30);
        box-shadow: 0px 0px 42px 0px rgba(255, 255, 255, 0.40) inset;
        backdrop-filter: blur(32px);
        overflow: hidden;
        .login-warp-bg{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 32px;
          box-sizing: border-box;
          display: flex;
          // background-image: url(./images/grey-mask.png);
          // mix-blend-mode: lighten;
          background-repeat: repeat;
          backdrop-filter: blur(32px);
        }
      }
      .layer-city{
        width: 40%;
        height: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
      .other-bg-icon{
        top: 60px;
        left: 78px;
        position: fixed;
        width: 280px;
        height: 68px;
        background-image: url(../newLogin/images/logo.png);
        background-size: 100% 100%;
      }
      .content-warp{
        margin: auto;
      }
    }

  }
  .fade-enter-active, .fade-leave-active {
    transition: all .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: scale(0.95);
    opacity: 0;
  }
</style>
